<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="row mb-3">
        <div class="col-sm-4">
          <multiselect
            v-model="medicoSelected"
            @input="onChangeMedicoSelected"
            :options="medicos"
            placeholder=""
            label="nombre"
            track-by="id"
            select-label=""
            deselect-label="X">
            <span slot="caret" v-if="!(medicoSelected===null)"></span>
            <span slot="noResult">Sin Resultados</span>
            <span slot="noOptions">Lista Vacia</span>
          </multiselect>
        </div>
        <div class="col-sm-5 d-flex align-items-center">
          <span><strong>Total Deuda Médicos:</strong> {{ deuda_total | numeral('0,0.00') }}</span>
        </div>
        <div class="col-sm-3  pt-3">
          <div class="text-right" v-if="medicoSelected!==null">
            <label class="switch switch-success mr-3">
              <input type="checkbox" id="toggleTable"
                v-model="listadoActivo"
                @change="listarPagosMedico">
              <span class="slider"></span>
              <span>{{labelListadoActivos}}</span>
            </label>
          </div>
        </div>
      </div>
      <div class="row" v-if="listadoActivo">
        <div class="col-sm-12">
          <b-table class="table table-striped table-hover"
            :fields="fieldsVentasPorPagar"
            :items="listaTabla">
            <template #head(fecha)="data">
              <input
                id="checkAll"
                type="checkbox"
                class="form-check-input mt-1"
                :value="checkAll"
                :checked="isAllChecked && listaTabla.length > 0"
                @change="onClickCheckAll"
              >
              <span>{{data.label}}</span>
            </template>
            <template #cell(fecha)="data">
              <input
                type="checkbox"
                class="form-check-input mt-1"
                v-model="data.item.checkItem"
                @change="onChangeCheckItem(data)"
              >
              {{data.item.fecha}}
            </template>
            <template #cell(total)="data">
              {{data.item.total | numeral('0,0.00')}}
            </template>
            <template #cell(estado)>
              Pendiente
            </template>
          </b-table>
          <div class="row">
            <div class="col-sm-4 offset-sm-5">
              <div class="row">
                <div class="col-sm-4 offset-sm-1 text-right">
                  <label class="col-form-label">Total:</label>
                </div>
                <div class="col-sm-5 pl-0 pr-4">
                  <vue-numeric
                    disabled
                    class="form-control"
                    :value="getSumaEfectivo"
                    separator=","
                    v-bind:precision="2"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- Paginacion -->
          <div class="row">
            <div class="col-sm-8"></div>
            <div class="col-sm-4">
              <b-pagination
                v-model="paginaActualListaPorPagar"
                :total-rows="totalFilasListaPorPagar"
                :per-page="porPaginaListaPorPagar"
                @input="onChangeMedicoSelected"
                class="my-0 float-right"
              ></b-pagination>
            </div>
          </div>
          <!-- Fin Paginacion -->
        </div>
        <div class="col-sm-7"></div>
        <div class="col-sm-3" v-if="checkedList.length > 0">
          <div class="row">
            <div class="col-sm-6 pl-0">
              <vue-numeric
                disabled
                class="form-control"
                :value="sumaEfectivoCheckedList"
                separator=","
                v-bind:precision="2"
              />
            </div>
            <button
              class="btn btn-warning"
              @click="realizarPago">
              Pagar
            </button>
          </div>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-sm-12">
          <b-table class="table table-striped table-hover"
            :fields="fieldsListaPagos"
            :items="listaPagos">
            <template #cell(imprimir)="items">
              <i v-if="items.item.estado === 1"
                class="nav-icon i-File-Download text-info font-weight-bold"
                v-b-modal.modal-opciones
                @click="setItems(items.item)"
                style="cursor: pointer"></i>
            </template>
            <template  #cell(eliminar)="items">
              <i v-if="items.item.estado === 1"
                class="nav-icon i-Close-Window text-danger font-weight-bold"
                v-b-modal.modal-eliminar
                @click="setItems(items.item)"
                style="cursor: pointer"></i>
            </template>
            <template #cell(fecha)="data">
              <span
                :class="(data.item.estado === 1)? 'text-dark':'text-danger'">
                {{data.item.fecha}}
              </span>
            </template>
            <template #cell(hora)="data">
              <span
                :class="(data.item.estado === 1)? 'text-dark':'text-danger'">
                {{data.item.hora}}
              </span>
            </template>
            <template #cell(numero)="data">
              <span
                :class="(data.item.estado === 1)? 'text-dark':'text-danger'">
                {{data.item.numero}}
              </span>
            </template>
            <template #cell(medico)="data">
              <span
                :class="(data.item.estado === 1)? 'text-dark':'text-danger'">
                {{data.item.medico}}
              </span>
            </template>
            <template #cell(efectivo)="data">
              <span
                :class="(data.item.estado === 1)? 'text-dark':'text-danger'">
                {{data.item.efectivo | numeral('0,0.00')}}
              </span>
            </template>
            <template #cell(usuario)="data">
              <span
                :class="(data.item.estado === 1)? 'text-dark':'text-danger'">
                {{data.item.usuario}}
              </span>
            </template>
          </b-table>
          <b-modal id="modal-opciones"
            title="Seleccione para Ver"
            hide-footer>
            <div class="row mt-4">
              <div class="col-sm-6 text-center">
                <button class="btn th-custom-color col-sm-10"
                  @click="downloadNotaPago()">
                  NOTA
                </button>
              </div>
            </div>
          </b-modal>
          <b-modal id="modal-eliminar"
            :title="'Seguro que quieres eliminar el Pago - N° '+selectedNro+'?'"
            hide-footer>
            <div class="row">
              <div class="col-sm-6">
                <button
                  class="col-sm-12 btn btn-danger"
                  @click="eliminarRegistroListaPagos">
                  Eliminar
                </button>
              </div>
              <div class="col-sm-6">
                <button class="col-sm-12 btn btn-outline-dark"
                @click="$bvModal.hide('modal-eliminar')">Cancelar</button>
              </div>
            </div>
          </b-modal>
          <!-- Paginacion -->
          <div class="row">
            <div class="col-sm-8"></div>
            <div class="col-sm-4">
              <b-pagination
                v-model="paginaActualListaPagos"
                :total-rows="totalFilasListaPagos"
                :per-page="porPaginaListaPagos"
                @input="listarPagosMedico"
                class="my-0 float-right"
              ></b-pagination>
            </div>
          </div>
          <!-- Fin Paginacion -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import VueNumeric from 'vue-numeric';
import axios from '../../common/axiosERP';
import { util } from '../../../plugins/util';

export default {
  name: 'PagoPendienteMedico',
  components: {
    Multiselect,
    VueNumeric,
  },
  data() {
    return {
      medicoSelected: null,
      medicos: [],
      fieldsVentasPorPagar: [
        {
          key: 'fecha', label: 'Fecha', class: 'text-center', thClass: 'th-custom-color', thStyle: { width: '15%' }, sortable: true,
        },
        {
          key: 'numero', label: 'N°', thClass: 'th-custom-color', thStyle: { width: '8%' }, sortable: true,
        },
        {
          key: 'paciente', label: 'Paciente', class: 'text-center', thClass: 'th-custom-color', thStyle: { width: '20%' }, sortable: true,
        },
        {
          key: 'numero_factura', label: 'N° Factura', class: 'text-center', thClass: 'th-custom-color', thStyle: { width: '15%' }, sortable: true,
        },
        {
          key: 'total', label: 'Caja', thClass: 'th-custom-color', thStyle: { width: '12%' }, sortable: true,
        },
        {
          key: 'estado', label: 'Estado', class: 'text-center', thClass: 'th-custom-color', thStyle: { width: '12%' }, sortable: true,
        },
        {
          key: 'usuario', label: 'Usuario', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '18%' }, sortable: true,
        },
      ],
      listaTabla: [],
      fieldsListaPagos: [
        {
          key: 'imprimir', label: ' ', class: 'text-center', thClass: 'th-custom-color', thStyle: { width: '4%' },
        },
        {
          key: 'eliminar', label: ' ', class: 'text-center', thClass: 'th-custom-color', thStyle: { width: '4%' },
        },
        {
          key: 'fecha', label: 'Fecha', class: 'text-center', thClass: 'th-custom-color', thStyle: { width: '15%' }, sortable: true,
        },
        {
          key: 'hora', label: 'Hora', class: 'text-center', thClass: 'th-custom-color', thStyle: { width: '10%' }, sortable: true,
        },
        {
          key: 'numero', label: 'N°', class: 'text-center', thClass: 'th-custom-color', thStyle: { width: '8%' }, sortable: true,
        },
        {
          key: 'medico', label: 'Medico', class: 'text-center', thClass: 'th-custom-color', thStyle: { width: '29%' }, sortable: true,
        },
        {
          key: 'efectivo', label: 'Efectivo', class: 'text-center', thClass: 'th-custom-color', thStyle: { width: '12%' }, sortable: true,
        },
        {
          key: 'usuario', label: 'Usuario', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '18%' }, sortable: true,
        },
      ],
      listaPagos: [],
      selectedIdListaPagos: null,
      checkAll: false,
      checkedList: [],
      pdf: null,
      listadoActivo: true,
      selectedNro: null,
      paginaActualListaPorPagar: 1,
      totalFilasListaPorPagar: 0,
      porPaginaListaPorPagar: 100,
      paginaActualListaPagos: 1,
      totalFilasListaPagos: 0,
      porPaginaListaPagos: 100,
      deuda_total: 0,
    };
  },
  created() {
    this.cargarDatosIniciales();
    this.getDeudaTotal();
  },
  methods: {
    async cargarDatosIniciales() {
      try {
        const RESPONSE = await axios.get('/clinic/reporte');
        this.medicos = RESPONSE.data.data.medicos;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async getDeudaTotal() {
      try {
        const response = await axios.get('/clinic/reporte/pago_pendiente_medico/deuda/total');
        this.deuda_total = response.data.data.total_deuda;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async onChangeMedicoSelected() {
      this.checkAll = false;
      this.listadoActivo = true;
      this.listaPagos = [];
      if (this.medicoSelected === null) {
        this.listaTabla = [];
        this.totalFilasListaPorPagar = 0;
        return;
      }
      const ID = this.medicoSelected.id;
      try {
        const REQUEST = {
          filterKey: '',
          page: this.porPaginaListaPorPagar,
          per_page: this.paginaActualListaPorPagar,
        };
        const RESPONSE = await axios.get(`/clinic/reporte/pago_pendiente_medico/${ID}`, {
          params: REQUEST,
        });
        this.totalFilasListaPorPagar = RESPONSE.data.data.total;
        this.listaTabla = RESPONSE.data.data.ventas;
        this.unCheckAllItems();
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async realizarPago() {
      const REQUEST = {
        medico_id: this.medicoSelected.id,
        monto: this.sumaEfectivoCheckedList,
        venta_ids: this.checkedList,
      };
      try {
        const RESPONSE = await axios.post('/clinic/reporte/pago_medico', REQUEST, {
          responseType: 'blob',
        });
        const blob = new Blob([RESPONSE.data], { type: 'application/pdf' });
        const downloadUrl = URL.createObjectURL(blob);
        window.open(downloadUrl, 'Reporte');
        util.showNotify(RESPONSE.data.message, 'success');
        this.listaTabla = [];
        this.totalFilasListaPorPagar = 0;
        this.checkedList = [];
        this.medicoSelected = null;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async listarPagosMedico() {
      if (this.medicoSelected === null) {
        util.showNotify('Seleccione un Medico', 'warn');
        return;
      }
      const ID_MEDICO = this.medicoSelected.id;
      try {
        const REQUEST = {
          filterKey: '',
          page: this.porPaginaListaPagos,
          per_page: this.paginaActualListaPagos,
        };
        const URL = `/clinic/reporte/pago_pendiente_medico/pagos/${ID_MEDICO}`;
        const RESPONSE = await axios.get(URL, {
          params: REQUEST,
        });
        this.totalFilasListaPagos = RESPONSE.data.data.total;
        this.listaPagos = RESPONSE.data.data.pagos;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    checkAllItems() {
      this.checkedList = [];
      this.listaTabla.forEach((item, index) => {
        this.listaTabla[index].checkItem = true;
        this.checkedList.push({
          id: item.id,
          efectivo: item.total,
        });
      });
    },
    unCheckAllItems() {
      this.listaTabla.forEach((item, index) => {
        this.listaTabla[index].checkItem = false;
      });
      this.checkedList = [];
    },
    onClickCheckAll() {
      this.checkAll = !this.checkAll;
      if (this.checkAll) {
        this.checkAllItems();
      } else {
        this.unCheckAllItems();
      }
    },
    onChangeCheckItem(data) {
      if (data.item.checkItem) {
        this.checkedList.push({
          id: data.item.id,
          efectivo: data.item.total,
        });
      } else {
        const INDEX = this.checkedList.findIndex((el) => el.id === data.item.id);
        this.checkedList.splice(INDEX, 1);
      }
      this.checkAll = this.isAllChecked;
    },
    async downloadNotaPago() {
      try {
        const URL_PDF = `/clinic/reporte/pago_pendiente_medico/pdf/${this.selectedIdListaPagos}`;
        const RESPONSE = await axios.get(URL_PDF, {
          responseType: 'blob',
        });
        const BLOB = new Blob([RESPONSE.data], { type: 'application/pdf' });
        const DOWNLOAD_URL = URL.createObjectURL(BLOB);
        window.open(DOWNLOAD_URL, 'Reporte de Pago');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    setItems(fila) {
      this.selectedIdListaPagos = fila.id;
      this.selectedNro = fila.numero;
    },
    async eliminarRegistroListaPagos() {
      this.$bvModal.hide('modal-eliminar');
      try {
        const URL = `/clinic/reporte/pago_pendiente_medico/destroy/${this.selectedIdListaPagos}`;
        const RESPONSE = await axios.delete(URL);
        util.showNotify(RESPONSE.data.message, 'success');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
      this.listarPagosMedico();
    },
  },
  computed: {
    getSumaEfectivo() {
      let subTotal = 0.00;
      this.listaTabla.forEach((item) => {
        subTotal = parseFloat(subTotal) + parseFloat(item.total);
      });
      return subTotal;
    },
    sumaEfectivoCheckedList() {
      const ACUMULADOR_INIT = 0.00;
      const SUMATORIA = this.checkedList.reduce((acumulador, item) => (
        acumulador + parseFloat(item.efectivo)
      ), ACUMULADOR_INIT);
      return SUMATORIA;
    },
    isAllChecked() {
      const status = this.checkedList.length === this.listaTabla.length;
      return status;
    },
    labelListadoActivos() {
      if (this.listadoActivo) {
        return 'Ventas por Pagar';
      }
      return 'Lista de Pagos';
    },
  },
};
</script>

<style scoped>
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
